<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>User Export</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-security-level" label="Security Level">
                                    <b-input-group size="sm">
                                        <b-select id="search-security-level" v-model="criteria.role" :options="roleOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.role = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-username" label="Username:">
                                    <b-input-group size="sm">
                                        <b-input id="search-username" placeholder="Username" v-model="criteria.username"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.username = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-lastname" label="Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-lastname" placeholder="Last Name" v-model="criteria.lastname"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-ubcid" label="UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-ubcid"
                                                 v-model="criteria.ubcId"
                                                 placeholder="UBC ID"  />
<!--                                        <ubc-id id="search-ubcid" v-model="criteria.ubcId" input-class="form-control form-control-sm"/>-->
                                        <b-input-group-addon>
                                            <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-district" label="District:">
                                    <b-input-group size="sm">
                                        <b-select id="search-district" v-model="criteria.district" :options="districtOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.district = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-council" label="Council:">
                                    <b-input-group size="sm">
                                        <b-select id="search-council" v-model="criteria.council" :options="councilOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.council = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-contractor-type" label="Contractor Type:">
                                    <b-input-group size="sm">
                                        <b-select id="search-contractor-type" v-model="criteria.contractorType" :options="contractorTypeOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.contractorType = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-contractor" label="Contractor:">
                                    <b-input-group size="sm">
                                        <b-select id="search-contractor" v-model="criteria.contractor" :options="contractorOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.contractor = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-organization" label="Organization:">
                                    <b-input-group size="sm">
                                        <b-select id="search-organization" v-model="criteria.organization" :options="organizationOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.organization = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-state" label="State/Province:">
                                    <b-input-group size="sm">
                                        <b-select id="search-state" v-model="criteria.state" :options="stateOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.state = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-graduation-date" label="Graduation Date:">
                                    <picker-of-dates id="search-graduation-date"
                                                     v-model="criteria.graduationDate"
                                                     format="M/d/yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-program" label="Program:">
                                    <b-input-group size="sm">
                                        <b-select id="search-program"
                                                  v-model="criteria.programs"
                                                  :options="programs"
                                                  :select-size="8"
                                                  multiple/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.programs = [];criteria.session = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3" v-if="!!criteria.programs && criteria.programs.length > 0">
                                <b-form-group label-for="search-session" label="Session:">
                                    <b-input-group size="sm">
                                        <PickerOfDates v-model="criteria.session"
                                                       lang="en"
                                                       type="month"
                                                       placeholder="Session Month/Year"
                                                       :clearable="false"
                                                       input-class="form-control form-control-sm"
                                                       format="MMMM yyyy"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.session = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-trainee-status" label="Trainee Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-trainee-status" v-model="criteria.traineeStatus" :options="traineeStatusOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.traineeStatus = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-mentor-status" label="Mentor Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-mentor-status" v-model="criteria.mentorStatus" :options="mentorStatuses">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.mentorStatus = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-user-status" label="User Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-user-status" v-model="criteria.userStatus" :options="userStatuses">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.userStatus = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import UbcId from '@/components/shared/UbcId.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {RoleOptions} from '@/model/security_level';
import download from '@/util/downloader';
import {errorModalOptions, errorToastOptions} from "@/util/formatters";

@Component({
    components: {
        UbcId,
        PickerOfDates
    }
})
export default class UserExport extends Vue {
    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    get roleOptions() {
        return RoleOptions;
    }

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.id,
                text: d.name
            };
        });
    }

    get councilOptions() {

        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);

        return _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.id,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    get states() {
        return this.$store.getters['common/states'];
    }

    get stateOptions() {
        const grouped = _.groupBy(this.states, (s) => s.countryAbbreviation);

        return [{
            label: 'United States of America',
            options: this.getStateOptions(grouped.US)
        }, {
            label: 'Canada',
            options: this.getStateOptions(grouped.CA)
        }];
    }

    get programs() {
        return [
            'Trainee Program 1',
            'Trainee Program 2',
            'Trainee Program 3',
            'Trainee Program 4',
            'Mentor Program 1',
            'Mentor Program 3',
            'Instructors'
        ];
    }

    get traineeStatuses() {
        return this.$store.getters['trainees/statuses'];
    }

    get traineeStatusOptions() {
        return _.map(this.traineeStatuses, (s) => {
            return {
                value: s.id,
                text: s.status
            };
        });
    }

    get mentorStatuses() {
        return [{
            value: true,
            text: 'Active'
        }, {
            value: false,
            text: 'Inactive'
        }];
    }

    get userStatuses() {
        return [{
            value: true,
            text: 'Enabled'
        }, {
            value: false,
            text: 'Disabled'
        }];
    }

    get contractorTypes() {
        return this.$store.getters['organizations/getContractorTypes'];
    }

    get contractorTypeOptions() {
        return _.map(this.contractorTypes, (t) => {
            return {
                value: t.id,
                text: t.type
            };
        });
    }

    get organizations() {
        return this.$store.getters['organizations/getOrganizations'];
    }

    get contractorOptions() {
        return _.chain(this.organizations)
                .filter((o) => !!o.type)
                .sortBy((o) => o.name)
                .map((o) => {
                    return {
                        value: o.id,
                        text: o.name
                    }
                })
                .value();
    }

    get organizationOptions() {
        return _.chain(this.organizations)
            .filter((o) => !o.type)
            .sortBy((o) => o.name)
            .map((o) => {
                return {
                    value: o.id,
                    text: o.name
                }
            })
            .value();
    }

    getStateOptions(states) {
        return _.chain(states)
            .sortBy((s) => s.state)
            .map((s) => {
                return {
                    value: s.id,
                    text: sprintf('%s (%s)', s.state, s.stateAbbreviation)
                }
            })
            .value();
    }

    clearFilters() {
        this.criteria.clear();
    }

    async getCsv() {
        const criteria = this.criteria.serialize();
        try {
            download('POST', '/api/v1/export/users', JSON.stringify(criteria));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('User Export', null, true));
        _.each([
            'common/loadStates',
            'common/loadDistricts',
            'common/loadCouncils',
            'trainees/loadTraineeStatuses',
            'organizations/loadContractorTypes',
            'organizations/loadOrganizations'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        this.clearFilters();
        // console.log(this.criteria);
    }

    beforeDestroy() {
        this.clearFilters();
    }
}
</script>


<style scoped>

</style>
